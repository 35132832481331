import React from "react";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useState } from "react";

import Subscribe from "../subscribe/subscribe_2";
import BannerWrapper from "../top_banner/banner_wrapper";
import ResourcesPopupHBSPT from "./resources_popup_hbspt";

import * as classes from "../top_banner/top_banner.module.scss";
import { useTranslation } from 'react-i18next';

const Resources_2 = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  // const breakpoints = useBreakpoint();
  const {t} = useTranslation();
  
  return (
    <>
      <div className={`conatiner font-sans`}>
        <BannerWrapper>
          <div
            className={` flex flex-col md:flex-row  justify-center  items-center h-full  mx-auto ${classes.wrapper}`}
          >
            <div
              className={`flex flex-col lg:flex-row my-10 justify-center items-center w-full  ${classes.subWrapper} `}
            >
              <div
                className={`w-full lg:w-6/12 md:mb-32 lg:mb-0 flex flex-col md:items-end  p-0 h-full ${classes.leftHalfWrapper}`}
              >
                <div
                  className={`flex flex-col w-11/12 md:w-10/12 space-y-4  mx-auto  md:mx-0 items-center lg:items-start  mb-5 md:mb-0   `}
                >
                  <div
                    className={`text-xs md:text-lg font-semibold text-center lg:text-left opacity-70 `}
                  >
                    Resource
                  </div>
                  <div
                    className={`  text-center lg:text-left font-bold text-3xl md:text-6xl `}
                  >
                    Ce que pensent <br className="" /> vraiment les étudiants
                  </div>
                  <div
                    className={`   text-center lg:text-left font-normal w-full md:w-1/2 lg:w-full   ${classes.description}`}
                    style={{ textAlign: "justify" }}
                  >
                    Ce livre blanc publié par la Junior Entreprise de l’EDHEC a pour 
                    objectif d’aider les responsables d’établissement à comprendre 
                    le regard des étudiants sur les examens en ligne. 
                  </div>
                  <div
                    className={`w-11/12 md:w-2/3 h-14 md:h-16 flex  justify-center lg:justify-start`}
                  >
                    <button
                      className={`border-0 text-lg lg:text-xl w-full  py-3 px-5 xl:px-10 h-full flex flex-row justify-center items-center space-x-2 bg-white text-black cursor-pointer font-md outline-none rounded-md my-4 mx-auto md:mx-0 font-semibold `}
                      onClick={() => setModalOpen(!isModalOpen)}
                    >
                      <img
                        alt="resources"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/download_icon.svg"
                        className="my-0 h-5"
                      />{" "}
                      <span className="text-sm md:text-xl">
                        Télécharger ce livre blanc
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`w-full  lg:w-7/12 bg-cover  bg-no-repeat p-0  md:my-0 h-full ${classes.rightHalfWrapper}`}
              >
                <img
                  src="https://d2lxkizvrhu4im.cloudfront.net/images/Learning languages-rafiki 1.svg"
                  alt="img banner"
                  className="mx-auto m-0 p-0 "
                  width="100%"
                  style={{ maxHeight: "550px", maxWidth: "750px" }}
                />
              </div>
            </div>
          </div>
        </BannerWrapper>
        {isModalOpen ? (
          <div>
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            >
            </div>
            <dialog
              open
              className="resourcesPopupFormStatic bg-white text-center rounded-lg space-y-2 shadow-xl z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fixed mx-auto w-11/12 lg:w-2/4"
            >
              <button className="w-full flex flex-row justify-end focus:outline-none absolute top-3 right-3">
                <img
                  alt="header"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/close_icon.svg"
                  onClick={() => setModalOpen(!isModalOpen)}
                  className="cursor-pointer"
                />
              </button>
              <div className="resources_static_hide w-11/12  mx-auto space-y-2">
                <div className="text-black my-0 text-xl pt-10">
                  « Mythes et réalités : les examens en ligne »
                </div>
                <div className="resources_static_hide text-black text-2xl pb-3">
                  Pour recevoir votre livret blanc par email <br />
                  <b>Veuillez indiquer vos coordonnées ci-dessous:</b>
                </div>
                <div className="resources_static_hide flex flex-col md:flex-row space-y-2 lg:space-y-0 md:space-x-2 items-center py-1 mx-auto">
                  <div className=" flex flex-row justify-start border-2 w-full md:w-1/2 rounded-md items-center  h-full">
                    <img
                      alt="contact"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_icon.svg"
                      className="m-3"
                    />
                    <input
                      name="firstname"
                      placeholder="Nom"
                      className="rounded-2xl w-full h-full text-sm focus:outline-none text-black p-2"
                    />
                  </div>

                  <div className=" flex flex-row justify-start border-2 w-full md:w-1/2 rounded-md items-center  ">
                    <img
                      alt="contact"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_icon.svg"
                      className="m-3"
                    />
                    <input
                      name="lastname"
                      placeholder="Prénom"
                      className="rounded-2xl w-full h-full text-sm focus:outline-none text-black p-2"
                    />
                  </div>
                </div>
                <div className="resources_static_hide flex flex-row justify-start border-2  mx-auto rounded-md items-center  ">
                  <img
                    alt="contact"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/message_icon.svg"
                    className="m-3"
                  />
                  <input
                    name="email"
                    placeholder="Email professionnel"
                    className="rounded-2xl w-full h-full text-sm focus:outline-none text-black p-2"
                  />
                </div>
                <div className="resources_static_hide flex flex-row justify-start border-2  mx-auto rounded-md items-center  py-1">
                  <img
                    alt="contact"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/call_icon.svg"
                    className="m-3"
                  />
                  <input
                    name="phone"
                    placeholder="Numéro de téléphone"
                    className="rounded-2xl w-full h-full text-sm focus:outline-none text-black p-2"
                  />
                </div>
                <div className="resources_static_hide flex flex-row justify-start border-2  mx-auto rounded-md items-center  py-1">
                  <img
                    alt="contact"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/work_icon.svg"
                    className="m-3"
                  />
                  <select
                    name="jobtitle"
                    style={{appearence: "none"}}
                    placeholder="Fonction"
                    className="rounded-2xl w-full h-full text-sm focus:outline-none text-black p-2"
                  >
                    <option value="">Fonction</option>
                    <option value="Directeur Général">Directeur Général</option>
                    <option value="Directeur Technique">Directeur Technique</option>
                    <option value="Recruteur">Recruteur</option>
                    <option value="Doyen, Proviseur, Enseignant">Doyen, Proviseur, Enseignant</option>
                    <option value="Responsable Opérationnel">Responsable Opérationnel</option>
                    <option value="Administrateur LMS">Administrateur LMS</option>
                    <option value="Autre">Autre</option>
                  </select>
                </div>
                <div className="resources_static_hide">
                  <button className="resources_submit_static flex flex-row justify-center bg-yellow-300 w-full mx-auto rounded-md items-center space-x-3 py-4 mt-4">
                    <img
                      alt="contact"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/send_icon.svg"
                      className="my-1 h-4"
                    />
                    <span className="text-black font-bold text-xl">
                      {t('send')}
                    </span>
                  </button>
                </div>
              </div>
              <div className="resources_results_container text-xl font-semibold text-black"></div>
              <ResourcesPopupHBSPT />
            </dialog>
          </div>
        ) : (
          ""
        )}
        {/* sub Container */}
        <div className={`mx-auto w-11/12 my-10 md:my-20`}>
          <div className="md:w-10/12 mx-auto ">
            <div className="flex flex-row justify-center  md:h-24 ">
              <img alt="resources" src="https://d2lxkizvrhu4im.cloudfront.net/icons/rocket_icon.svg" />
            </div>
            <div className="text-3xl  md:text-5xl font-semibold text-center my-3 md:my-5 relative">
              <img
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
                alt="left star"
                className="absolute -right-10 -top-10 w-16"
              />
              De l’importance d’une surveillance en ligne
            </div>
            {/* <div className="text-md  text-center mx-auto my-5 ">
              When applied to building block a website or similar work product,
              a Visual Guide can be an intermediate step toward the end goal of
              a complete website. By creating a visual guide along the way.
            </div> */}
            <div className="flex flex-col md:flex-row  justify-between items-center mt-10 md:mt-32  space-y-10 md:space-y-0">
              <div className="flex flex-col w-2/3  md:w-1/4 space-y-3">
                <div className="text-5.3xl md:text-7xl text-yellow-500 text-medium text-center">
                  75%
                </div>
                <div className="text-md text-gray-500  text-center text-sm md:text-base font-normal">
                  des étudiants sont favorables 
                  à l’adoption des examens 
                  en ligne dans leur formation
                </div>
              </div>

              <div className="flex flex-col w-2/3  md:w-1/4 space-y-3">
                <div className="text-5.3xl md:text-7xl text-yellow-500 text-medium text-center">
                  43%
                </div>
                <div className=" text-sm md:text-base text-md text-gray-500  text-center font-normal">
                  des étudiants considère 
                  qu’un examen non surveillé 
                  n’a aucune valeur
                </div>
              </div>

              <div className="flex flex-col w-2/3  md:w-1/4 space-y-3">
                <div className="text-5.3xl md:text-7xl text-yellow-500 text-medium text-center">
                  89%
                </div>
                <div className=" text-gray-500 text-center text-sm md:text-base font-normal">
                  des étudiants jugent essentielle 
                  la présence d’un service 
                  support francophone
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Banner */}
        <Subscribe />
      </div>
    </>
  );
};

export default Resources_2;
