import * as React from "react";

import Resource2Page from "../components/resources/resources_2";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";

const Blog = () => {
  return (
    <>
      <Seo
        title="Blog"
        description="Découvrez les dernières actualités et tendances en matière de recrutement."
      />
      <Layout>
        <Resource2Page />
      </Layout>
    </>
  );
};

export default Blog;
